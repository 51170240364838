import classNames from "classnames"
import _ from "lodash"
import { default as React } from "react"
import "typeface-lato"
import Header from "../components/header/Header"
import Logo from "../components/header/Logo"
import Layout from "../components/layout"
import styles from "../styles/content.module.styl"
import headerStyles from "../styles/header.module.styl"

const Glossary = ({ location, data, ...props }) => {
  const nodeData = data.decisionAidsJson

  return (
    <Layout>
      <Header>
        <Logo />
        <div className={headerStyles.overlay}>
          <div className={headerStyles.title}>Glossary</div>
        </div>
      </Header>
      <div className={styles.content}>
        {_.map(nodeData.glossary, glossaryChapter => {
          return (
            <div>
              <h2
                className={classNames(
                  styles.sectionTitle,
                  styles.mb10,
                  styles.mt10,
                  styles.ml20
                )}
              >
                <strong>{glossaryChapter.title}</strong>
              </h2>
              <ul className={styles.unstyledList}>
                {_.map(glossaryChapter.content, glossary => {
                  return (
                    <li className={styles.mb20}>
                      <strong
                        style={{
                          display: "block",
                          width: "250px",
                          float: "left",
                          lineHeight: "28px",
                          marginRight: "10px",
                        }}
                      >
                        {glossary.title}:
                      </strong>
                      <p style={{ overflow: "hidden", lineHeight: "28px" }}>
                        {glossary.content}
                      </p>
                      <div style={{ clear: "both" }} />
                    </li>
                  )
                })}
              </ul>
            </div>
          )
        })}
      </div>
    </Layout>
  )
}

export default Glossary
export const query = graphql`
  query GlossaryQuery($pageId: String) {
    decisionAidsJson(pageId: { eq: $pageId }) {
      title
      subPageTitle
      logo
      glossary {
        generalTerms {
          title
          content {
            title
            content
          }
        }
        vteRelatedTerms {
          title
          content {
            title
            content
          }
        }
      }
    }
  }
`
